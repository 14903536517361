<template>
    <v-container class="pt-5 px-0" fluid>
        <v-container class="content mb-4">
            <v-row no-gutters>
                <v-col cols="12" md="8">
                    <v-container :class="'py-0 pl-0' + ($vuetify.breakpoint.smAndDown ? ' pr-0' : '')">
                        <v-row no-gutters v-if="$db.news.top.length >= 1" :style="'height: ' + ($vuetify.breakpoint.xs ? 1024 : 496) + 'px'">
                            <v-col cols="12" sm="6" :class="$vuetify.breakpoint.xs ? 'mb-6' : ''">
                                <Box-Image :data="$db.news.top[0]" :class="$vuetify.breakpoint.smAndUp ? 'mr-6' : ''" />
                            </v-col>
                            <v-col cols="12" sm="6" v-if="$db.news.top.length >= 2">
                                <v-row no-gutters :class="`pb-${$db.news.top.length >= 3 ? 3 : 0}`" :style="`height: ${$db.news.top.length >= 3 ? 50 : 100}%`">
                                    <v-col>
                                        <Box-Image :data="$db.news.top[1]" />
                                    </v-col>
                                </v-row>
                                <v-row v-if="$db.news.top.length >= 3" no-gutters class="pt-3" style="height: 50%">
                                    <v-col >
                                        <Box-Image :data="$db.news.top[2]" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
                <v-col cols="12" md="4" >
                    <v-container :class="$vuetify.breakpoint.mdAndUp ? 'pr-0 py-0' : ''">
                        <v-row v-for="tweet in $db.news.latestTweets.slice(0, 2)" :key="tweet.id">
                            <Tweet :tweet="tweet" />
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <Banner />

        <v-container class="content">
            <Headline text="Über uns" class="pt-6" anchor="about" />
            <v-row no-gutters>
                <v-col cols="12" md="8">
                    <Box-Text title="Das ist axxez..." text="...eine Multi-Gaming Community mit familiärer Atmosphäre seit 2007. Über die Jahre habe wir viele neue Mitglieder aus den unterschiedlichsten Spielegenres in unseren Reihen begrüßen dürfen. In den letzten Jahren kam von verschiedenen Mitgliedern immer wieder der Wunsch auf, den Namen <axXez> in den eSport Bereich zu bringen und dort etablieren zu können.

Einen ersten Schritt haben wir dafür mit unserem Counter-Strike: Global Offensive Team getan, dass in den deutschen eSport Ligen unterwegs ist. Auch unser Dota 2 Team konnte sich bereits bis in die ESL Meisterschaft Relegation spielen, wo sie sich bis ins Halbfinale kämpften und damit nur knapp an dem Einzug in die ESL Meisterschaft scheiterten.

Wir sind stets auf der Suche nach neuen Spielerinnen und Spielern, egal ob Anfänger, Semi-Hardcore oder Pro-Gamer. Bei uns bist du genau richtig!

Neben den oben genannten Spieletiteln findest du unter anderem auch Anschluss in Titeln wie World of Warcraft, League of Legends, Minecraft, Rocket League, Trouble in Terrorist Town, Among Us und viele anderen Spielen.
                    " />
                    <!--v-container>
                        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                            <v-timeline-item v-for="(item, i) in timeline" :key="i" color="box" fill-dot>
                                <v-card color="box">
                                    <v-card-title>
                                        {{item.title}}
                                    </v-card-title>
                                    <v-card-text>
                                        {{item.text}}
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-container-->
                </v-col>
                <v-col cols="12" md="4">
                    <Box-Info title="Unsere eSport Titel" :entries="['Dota 2', 'Counter-Strike: Global Offensive']"/>
                    <Box-Info title="Unsere Community Features" :entries="['Teamspeak 3 Server', 'Diverse Gameserver']"/>
                </v-col>
            </v-row>

            <Headline text="Partner" class="pt-6" />
            <v-row>
                <v-col>
                    <v-container grid-list-xl>
                        <v-layout wrap :justify-start="$vuetify.breakpoint.mdAndUp" :justify-center="$vuetify.breakpoint.smAndDown">
                            <v-card v-for="(partner, i) in partners" :key="i" tile elevation="6" color="background" width="320px" class="ma-4">
                                <v-container>
                                    <v-row justify="center" align="center">
                                        <v-col cols="auto">
                                            <v-img :src="require(`@/assets/${partner.logo}`)" width="240px" height="120px" contain />
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" align="center">
                                        <v-col cols="auto">
                                            <v-btn v-for="(item, i) in partner.social" :key="i" x-small fab text :href="item.href" target="_blank"><v-icon>{{item.icon}}</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" align="center">
                                        <v-col cols="auto">
                                            <v-btn small text :href="partner.website" target="_blank">website</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-layout>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>  
    </v-container>
</template>

<script>
    export default {
        name: 'home',
        props: [ ],
        data (){
            return {
                partners: [
                    { logo: "imperio_logo.png", website: "https://imperio-esports.de", social: [
                        { icon: "fab fa-twitter", href: "https://twitter.com/imperiotrier" },
                        { icon: "fab fa-instagram", href: "https://www.instagram.com/imperiotrier" },
                        { icon: "fab fa-twitch", href: "https://www.twitch.tv/imperiotrier" },
                    ]},
                    { logo: "manatee_logo.png", website: "https://www.manatee.gg", social: [
                        { icon: "fab fa-twitter", href: "https://twitter.com/manateegg" },
                        { icon: "fab fa-instagram", href: "https://www.instagram.com/manateegg" },
                    ]},
                ],
                timeline: [
                    { title: "2021", text: "Neue Homepage und Merch release" },

                    { title: "2017", text: "Neue Homepage für CS:GO Team" },
                    { title: "2016 CS:GO Team", text: "Neues CS:GO Team" },
                    { title: "2009 Rebranding", text: "Umbenannt in 'axXez Sports'" },
                    { title: "2007 Gründung", text: "Gründung als 'shoX Gam!ng' Clan" },
                ]
            }
        },
        computed: {
            
        },
        components: {

        },
        mounted () {
            this.$db.news.loadTop();
            this.$db.news.loadLatestTweets();
        }
    }
</script>

<style scoped>

</style>